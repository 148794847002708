/**
 * Creates the contact object for form submission
 * @param {object} submitConfig - Form data from the ConfiguratorChoices components
 * @returns {object} The contact object
 */
const createContactObject = (submitConfig) => ({
  contact: {
    email: submitConfig.lead_mail,
    phone: submitConfig.lead_tel,
    firstName: submitConfig.lead_first_name,
    lastName: submitConfig.lead_second_name,
    fieldValues: [
      {
        field: "27", // type
        value: "Interessent/ Kunde",
      },
      {
        field: "3", // type
        value: submitConfig.lead_street_and_number,
      },
      {
        field: "2", // type
        value: submitConfig.lead_district,
      },
      {
        field: "1", // type
        value: submitConfig.lead_plz,
      },
    ],
  },
});

/**
 * Creates the deal object for form submission
 * @param {object} cfg - Configuration data from the ConfiguratorChoices components
 * @param {string} leadRoofArea - Lead roof area from URL query parameters
 * @param {object} submitConfig - Form data from the ConfiguratorChoices components
 * @returns {object} The deal object
 */
const createDealObject = (
  cfg,
  leadRoofArea,
  submitConfig,
  implementationType
) => {
  let type = "DIY";
  if (implementationType === "unsafe") {
    type = "Unsicher";
  } else if (implementationType === "heygruen") {
    type = "Handw.";
  }

  let price = 0;
  if (implementationType === "selfmade") {
    price = cfg.price.diy * 100;
  } else if (
    implementationType === "heygruen" ||
    implementationType === "unsafe"
  ) {
    price =
      (cfg.price.professional.from * 100 + cfg.price.professional.to * 100) / 2;
  }

  const title = `${type} - ${submitConfig.lead_plz} - ${leadRoofArea} m²`;
  const conditionalDescription = submitConfig.lead_comment
    ? "Kunden-Anmerkung beachten"
    : "Keine Kunden-Anmerkung";

  let someFields = [];
  if (implementationType !== "heygruen") {
    someFields = [
      // {
      //   customFieldId: 72, // DIY Orientierungspreis pro m²
      //   fieldValue: cfg.price.diy / leadRoofArea
      // },
      // {
      //   customFieldId: 161, // DIY Orientierungspreis Gesamt²
      //   fieldValue: cfg.price.diy,
      // },
      {
        customFieldId: 213, // 2DIY Orientierungspreis pro m²
        fieldValue: new Intl.NumberFormat("de-DE", {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }).format(cfg.price.diy / leadRoofArea),
      },
      {
        customFieldId: 214, // 2DIY Orientierungspreis Gesamt²
        fieldValue: new Intl.NumberFormat("de-DE", {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }).format(cfg.price.diy),
      },
    ];
  }

  return {
    deal: {
      contact: undefined, // is set through backend.
      title: title,
      description: conditionalDescription,
      group: implementationType === "selfmade" ? "1" : "3",
      stage: implementationType === "selfmade" ? "1" : "11",
      currency: "eur",
      value: price,
      owner: "1",
      status: 0,
      percent: null,
      fields: [
        ...someFields,
        {
          customFieldId: 67, // type
          fieldValue: leadRoofArea,
        },
        {
          customFieldId: 3, // type
          fieldValue: cfg.roof_age ?? "",
        },
        {
          customFieldId: 18, // type
          fieldValue: cfg.has_roof_load ?? "",
        },
        {
          customFieldId: 21, // Dachkonstruktion
          fieldValue:
            (implementationType === "selfmade"
              ? cfg.roof_type
              : cfg.has_corrugated_roof) ?? "",
        },
        {
          customFieldId: 66, // type
          fieldValue: cfg.roof_wood ?? "",
        },
        {
          customFieldId: 7, // type
          fieldValue: submitConfig.lead_street_and_number,
        },
        {
          customFieldId: 10, // type
          fieldValue: submitConfig.lead_district,
        },
        {
          customFieldId: 9, // type
          fieldValue: submitConfig.lead_plz,
        },
        {
          customFieldId: 63, // type
          fieldValue: submitConfig.lead_comment ?? "",
        },
        {
          customFieldId: 65, // type
          fieldValue: submitConfig.lead_accept_legal,
        },
        {
          customFieldId: 175, // Handwerker Orientierungspreis pro m²
          fieldValue:
            implementationType !== "selfmade"
              ? `${new Intl.NumberFormat("de-DE", {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                }).format(
                  cfg?.price?.professional?.from / leadRoofArea
                )} - ${new Intl.NumberFormat("de-DE", {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                }).format(cfg?.price?.professional?.to / leadRoofArea)}`
              : "",
        },
        {
          customFieldId: 174, // Handwerker Orientierungspreis Gesamt
          fieldValue:
            implementationType !== "selfmade"
              ? `${new Intl.NumberFormat("de-DE", {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                }).format(
                  cfg?.price?.professional?.from
                )} - ${new Intl.NumberFormat("de-DE", {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                }).format(cfg?.price?.professional?.to)}`
              : "",
        },
        {
          customFieldId: 130, // Umsetzungsart
          fieldValue: type === "Handw." ? "Handwerker" : type,
        },
        {
          customFieldId: 165, // E-Mail-Adresse
          fieldValue: submitConfig.lead_mail,
        },
        {
          customFieldId: 162, // Vorname
          fieldValue: submitConfig.lead_first_name,
        },
        {
          customFieldId: 163, // Nachname
          fieldValue: submitConfig.lead_second_name,
        },
        {
          customFieldId: 164, // Telefonnummer
          fieldValue: submitConfig.lead_tel,
        },
        {
          customFieldId: 125, // Trigger Automation (intern)
          fieldValue:
            implementationType === "selfmade"
              ? "1.0-D1 Bestätigung neue Anfrage"
              : "A1-H1 Bestätigungsmail Konfigurator",
        },
      ],
    },
  };
};

export { createDealObject, createContactObject };