import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import SectionM from "../../../../../@ui-common/SectionM/SectionM";
import { pricingOrientation$ } from "../../../services/configurator-service";
import Loading from "../../../../../@ui-common/Loading/Loading";

function ConfiguratorPrice({
  roofSize,
  implementationType,
  priceCallback,
  offerButton,
}) {
  const [professionalPrice, setProfessionalPrice] = useState({
    to: "",
    from: "",
  });
  const [selfmadePrice, setSelfmadePrice] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchPrices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchPrices = async () => {
    setLoading(true);

    pricingOrientation$(roofSize, implementationType).subscribe(
      async (response) => {
        const data = await response.json();

        switch (implementationType) {
          case "selfmade":
            setSelfmadePrice(
              new Intl.NumberFormat("de-DE", {
                style: "currency",
                currency: "EUR",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              }).format(data.diy)
            );
            break;
          case "heygruen":
            setProfessionalPrice({
              from: new Intl.NumberFormat("de-DE", {
                style: "currency",
                currency: "EUR",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              }).format(data.professional.from),
              to: new Intl.NumberFormat("de-DE", {
                style: "currency",
                currency: "EUR",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              }).format(data.professional.to),
            });
            break;
          default:
            setProfessionalPrice({
              from: new Intl.NumberFormat("de-DE", {
                style: "currency",
                currency: "EUR",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              }).format(data.professional.from),
              to: new Intl.NumberFormat("de-DE", {
                style: "currency",
                currency: "EUR",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              }).format(data.professional.to),
            });
            setSelfmadePrice(
              new Intl.NumberFormat("de-DE", {
                style: "currency",
                currency: "EUR",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              }).format(data.diy)
            );
        }

        setLoading(false);

        priceCallback(data.diy, data.professional);
      }
    );
  };

  const introText = () => {
    switch (implementationType) {
      case "selfmade":
        return "Die eigene Umsetzung mit der DIY-Gründachbox kostet:";
      case "heygruen":
        return "Die professionelle Umsetzung kostet voraussichtlich zwischen:";
      default:
        return "Die eigene Durchführung mit der DIY-Gründachbox kostet:";
    }
  };

  const outroText = () => {
    switch (implementationType) {
      case "selfmade":
        return `auf Basis deiner angegebenen Dachfläche von ${roofSize} m². Der Preis ändert sich minimal auf Basis deiner genauen Dachmaße.`;
      case "heygruen":
        return `auf Basis deiner angegebenen Dachfläche von ${roofSize} m². Der Preis hängt von deinen individuellen Dachgegebenheiten und Wünschen ab, die wir gemeinsam im nächsten Schritt prüfen.`;
      default:
        return "und solltest du dich für eine professionelle Durchführung von heygrün entscheiden kostet es dich voraussichtlich zwischen";
    }
  };

  return (
    <div className="pt-0 sm:pt-10">
      <div className="mb-small sm:mb-xlarge">
        <div className="p-4 corporate-gradient sm:p-0 sm:bg-none sm:mb-[0.5rem]">
          <p className="heyg-title">Dein Orientierungspreis:</p>
        </div>
        <div className="p-4 bg-[#1a1a1a] sm:p-0 sm:bg-transparent mb-8">
          <p className="text-4 sm:text-5 text-HEYG-white sm:text-[#1a1a1a]">
            <strong className="inline sm:hidden">Tipp: </strong>
            Wir haben eine erste Kalkulation auf Basis deiner angegebenen Daten
            vorgenommen
          </p>
        </div>
        <SectionM classname={"inline-block w-full"}>
          <>
            {loading ? (
              <Loading />
            ) : (
              <>
                <p>{introText()}</p>
                <div className="font-bold text-2xl w-100 text-center py-4">
                  {implementationType === "selfmade" ||
                  implementationType === "unsafe" ? (
                    <>{selfmadePrice}</>
                  ) : (
                    <>
                      {professionalPrice.from} - {professionalPrice.to}
                    </>
                  )}
                </div>
                <p>{outroText()}</p>
                {implementationType === "unsafe" && (
                  <>
                    <div className="font-bold text-2xl w-100 text-center py-4">
                      {professionalPrice.from} - {professionalPrice.to}
                    </div>
                    <p>
                      Die Kalkulation basiert auf deiner angegebenen Dachfläche
                      von {roofSize} m². Der endgültige Preis hängt von deinen
                      individuellen Dachgegebenheiten und Wünschen ab, die wir
                      gemeinsam im nächsten Schritt prüfen.
                    </p>
                  </>
                )}
              </>
            )}
            {offerButton}
          </>
        </SectionM>
      </div>
    </div>
  );
}

ConfiguratorPrice.propTypes = {
  roofSize: PropTypes.number.isRequired,
  implementationType: PropTypes.string.isRequired,
  priceCallback: PropTypes.func,
  offerButton: PropTypes.element,
};

export default ConfiguratorPrice;