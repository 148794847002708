import React from "react";
import SectionM from "../../../../@ui-common/SectionM/SectionM";
import HEYGChoiceBox from "../../../../@ui-common/HEYGChoiceBox/HEYGChoiceBox";
import PropTypes from "prop-types";
import HEYGHelpTooltip from "../../../../@ui-common/HEYGHelpTooltip/HEYGHelpTooltip";

function ImplementationChoice({ available, callback }) {
  return (
    <div className='pt-0 sm:pt-10'>
      <div className="mb-small sm:mb-xlarge">
        <div className="p-4 corporate-gradient sm:p-0 sm:bg-none sm:mb-[0.5rem]">
          <p className="heyg-title">
            {available
              ? "Wer soll deine Dachbegrünung durchführen?"
              : "In deiner Region ist aktuell nur eine eigene Umsetzung möglich"}
          </p>
        </div>
        <div className="p-4 bg-[#1a1a1a] sm:p-0 sm:bg-transparent">
          <p className="text-4 sm:text-5 text-HEYG-white sm:text-[#1a1a1a]">
            <strong className="inline sm:hidden">Tipp: </strong>
            {available
              ? "Wenn du die Dachbegrünung selbst durchführst, kannst du bis zu 50 € pro m² sparen."
              : "Die Durchführung mit der DIY-Gründachbox ist mit wenigen Handgriffen möglich und du sparst bis zu 50 € pro m²."}
          </p>
        </div>
      </div>
      <SectionM>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-2 sm:gap-3.5 auto-rows-fr">
          <HEYGChoiceBox
            key={1}
            name={"selfmade"}
            label={"Eigene Umsetzung"}
            ico={"selfmade.svg"}
            value={"selfmade"}
            cbOnOptionClick={callback}
          />
          {available && (
            <>
              <HEYGChoiceBox
                key={2}
                name={"heygruen"}
                label={"Umsetzung heygrün"}
                ico={"heygruen.svg"}
                value={"heygruen"}
                cbOnOptionClick={callback}
              />
              <HEYGChoiceBox
                key={3}
                name={"unsafe"}
                label={"Noch unsicher"}
                ico={"question-mark-32x32.svg"}
                value={"unsafe"}
                cbOnOptionClick={callback}
              />
            </>
          )}
        </div>
      </SectionM>
      <HEYGHelpTooltip />
    </div>
  );
}

ImplementationChoice.propTypes = {
  available: PropTypes.bool.isRequired,
  callback: PropTypes.func.isRequired,
};

export default ImplementationChoice;
